import React, { useLayoutEffect, useState } from "react";
import { motion } from "framer-motion";
import { SUPPORTED_REGIONS } from "../../../shared/enums";
import { useIsMobile } from "../../hooks";
import milaImg from "assets/images/landingAir/mila-order/milaair-order-bg.jpg";
import milaImgSm from "assets/images/landingAir/mila-order/milaair-order-bg-sm.jpg";
/**
 * @returns {JSX.Element}
 * @constructor
 */

const data = {
  airMini: {
    US: {
      offer: 199,
      regular: 199,
      dimentions: (
        <>
          714 ft<sup>2</sup>
        </>
      ),
    },
    CA: {
      offer: 270,
      regular: 270,
      dimentions: (
        <>
          66m<sup>2</sup>
        </>
      ),
    },
  },
  air: {
    US: {
      offer: 349,
      regular: 349,
      dimentions: (
        <>
          1000 ft<sup>2</sup>
        </>
      ),
    },
    CA: {
      offer: 470,
      regular: 470,
      dimentions: (
        <>
          93 m<sup>2</sup>
        </>
      ),
    },
  },
};

const MilaOrder = ({ localization }) => {
  const { region } = localization;
  const regional = SUPPORTED_REGIONS[region] || SUPPORTED_REGIONS.US;

  const isMobile = useIsMobile(769);
  const [isBg, setIsBg] = useState(milaImg);

  useLayoutEffect(() => {
    setIsBg(isMobile ? milaImgSm : milaImg);
  }, [isMobile]);

  return (
    <section className="mila-order">
      <div
        className="mila-order__wrapper"
        style={{ backgroundImage: `url(${isBg})` }}
      >
        <div className="container">
          <div className="mila-order__header">
            <motion.h2
              className="mila-order__title"
              initial={{ opacity: 0, y: 20 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
            >
              Bring Mila <br />
              into your airspace.
            </motion.h2>
            <motion.div
              className="mila-order__short-description"
              initial={{ opacity: 0, y: 20 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.01 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
            >
              <p>We guarantee you’ll become a Milieber, or your money back.</p>
            </motion.div>
          </div>
        </div>
        <div className="mila-order__overflow">
          <div className="mila-order__overflow-container">
            <div className="mila-order__grid">
              <motion.div
                className="mila-order__card mila-order__card--mini"
                initial={{ opacity: 0, y: 20 }}
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0 }}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  hidden: { opacity: 0, y: 20 },
                }}
              >
                <div className="mila-order__card-content">
                  <h3 className="mila-order__card-title">Mila Air Mini</h3>
                  <h4 className="mila-order__card-subtitle">
                    Ideal for medium-sized rooms
                  </h4>
                  <p className="mila-order__card-description">
                    {isMobile ? (
                      <>
                        Our compact, colorful addition, perfect for medium rooms
                        up to{" "}
                        {data.airMini[regional]
                          ? data.airMini[regional].dimentions
                          : data.airMini[SUPPORTED_REGIONS.US].dimentions}
                        .
                      </>
                    ) : (
                      <>
                        Our more compact and colorful addition to the family.
                        Perfect for medium-sized rooms, up to{" "}
                        {data.airMini[regional]
                          ? data.airMini[regional].dimentions
                          : data.airMini[SUPPORTED_REGIONS.US].dimentions}
                        .
                      </>
                    )}
                  </p>
                  <p className="mila-order__card-price">
                    Starting at{" "}
                    <strong>
                      ${data.airMini[regional]
                        ? data.airMini[regional].offer
                        : data.airMini[SUPPORTED_REGIONS.US].offer}{" "}
                      {
                      /*
                      <del>
                        ${data.airMini[regional]
                          ? data.airMini[regional].regular
                          : data.airMini[SUPPORTED_REGIONS.US].regular}
                      </del>
                      */
                      }
                    </strong>
                  </p>
                </div>
                <div className="mila-order__card-footer">
                  <a
                    href="https://airmini.milacares.com/"
                    className="button mila-order__card-button"
                  >
                    Order Now
                  </a>
                </div>
              </motion.div>
              <motion.div
                className="mila-order__card mila-order__card--3"
                initial={{ opacity: 0, y: 20 }}
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0 }}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  hidden: { opacity: 0, y: 20 },
                }}
              >
                <div className="mila-order__card-content">
                  <h3 className="mila-order__card-title">Mila Air 3</h3>
                  <h4 className="mila-order__card-subtitle">
                    Ideal for larger rooms and spaces
                  </h4>
                  <p className="mila-order__card-description">
                    Our OG air purifier with the muscle to handle <br /> larger
                    spaces, up to{" "}
                    {data.air[regional]
                      ? data.air[regional].dimentions
                      : data.air[SUPPORTED_REGIONS.US].dimentions}
                    .
                  </p>
                  <p className="mila-order__card-price">
                    Starting at{" "}
                    <strong>
                      ${data.air[regional]
                        ? data.air[regional].offer
                        : data.air[SUPPORTED_REGIONS.US].offer}{" "}
                      {
                      /*
                      <del>
                        ${data.air[regional]
                          ? data.air[regional].regular
                          : data.air[SUPPORTED_REGIONS.US].regular}
                      </del>
                      */
                      }
                    </strong>
                  </p>
                </div>
                <div className="mila-order__card-footer">
                  <a
                    href="https://milacares.com/buy"
                    className="button mila-order__card-button"
                  >
                    Order Now
                  </a>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MilaOrder;
